import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property vallue called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width="85%" viewBox="0 0 1036.000000 212.000000"
     preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,212.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M3776 1555 c-99 -35 -166 -132 -174 -250 -4 -66 -2 -78 24 -126 36
    -66 73 -92 256 -185 110 -56 156 -85 182 -116 71 -83 64 -199 -18 -271 -12
    -10 -46 -30 -76 -45 -48 -24 -67 -27 -155 -27 -93 0 -105 2 -167 33 -37 18
    -76 46 -88 62 -42 59 -38 139 10 193 34 39 28 66 -9 41 -32 -23 -61 -90 -61
    -142 0 -138 144 -230 345 -220 199 11 324 121 327 291 2 125 -52 183 -264 286
    -79 38 -163 83 -186 101 -131 95 -91 283 73 345 58 23 168 17 217 -12 45 -26
    78 -78 78 -122 0 -40 -21 -125 -38 -158 -9 -17 -9 -22 4 -27 28 -11 58 77 59
    169 0 98 -21 135 -99 171 -60 28 -175 32 -240 9z"/>
    <path d="M2036 1519 c-68 -54 -105 -195 -115 -444 l-7 -180 -36 -70 c-20 -38
    -53 -86 -73 -105 -31 -30 -43 -35 -85 -35 -34 0 -54 6 -70 20 -22 20 -59 89
    -60 111 0 7 24 13 55 16 92 8 155 72 155 158 0 77 -56 113 -129 84 -53 -21
    -106 -83 -127 -148 -56 -172 -133 -246 -192 -183 -29 31 -41 67 -48 147 -6 64
    -4 76 26 145 31 70 35 75 69 80 46 7 139 31 176 45 54 21 12 30 -47 10 -63
    -20 -148 -36 -148 -26 0 3 9 40 21 81 11 41 19 94 17 117 -3 36 -7 44 -26 46
    -13 2 -30 -2 -38 -9 -25 -20 -62 -101 -80 -174 l-17 -67 -35 7 c-20 4 -53 15
    -74 26 -32 16 -38 17 -38 5 0 -19 25 -33 90 -49 l51 -13 3 -145 c4 -201 34
    -272 117 -285 46 -7 92 23 131 87 l32 50 11 -46 c14 -52 46 -92 92 -111 95
    -41 183 7 258 139 19 34 35 55 35 46 0 -8 7 -39 16 -69 18 -62 61 -110 98
    -110 33 0 87 48 122 108 l28 47 7 -35 c9 -41 50 -80 85 -80 39 0 82 27 114 71
    l29 41 7 -29 c13 -56 47 -103 89 -123 112 -54 207 6 290 184 24 50 44 99 46
    110 3 15 14 19 52 20 26 0 47 -1 47 -4 0 -3 -7 -23 -15 -45 -8 -22 -14 -68
    -15 -103 0 -99 38 -152 110 -152 62 1 95 30 145 129 42 84 57 133 45 145 -7 7
    -13 3 -17 -14 -11 -43 -81 -175 -103 -195 -29 -26 -85 -25 -113 3 -19 20 -22
    33 -22 105 0 69 5 92 29 145 33 72 28 90 -12 44 -21 -25 -36 -33 -67 -35 l-40
    -2 2 40 c2 61 -15 117 -35 113 -28 -5 -37 -51 -23 -111 12 -50 12 -58 -13
    -125 -32 -90 -58 -141 -92 -181 -72 -85 -169 -79 -214 14 -31 65 -28 72 35 77
    88 7 144 61 152 145 4 42 1 54 -19 78 -19 22 -33 28 -66 28 -66 0 -114 -38
    -152 -120 -67 -145 -84 -174 -112 -198 -88 -75 -125 36 -78 233 22 92 24 112
    12 112 -11 0 -29 -41 -82 -185 -86 -232 -162 -282 -204 -136 -28 97 -22 145
    34 263 82 174 130 325 130 406 0 70 -69 104 -124 61z m84 -14 c19 -23 7 -137
    -25 -230 -30 -87 -103 -254 -118 -270 -15 -15 -1 248 17 332 19 89 38 135 65
    162 25 25 44 27 61 6z m-733 -234 c-23 -100 -43 -143 -64 -139 -13 2 -17 11
    -15 33 9 91 63 208 84 187 7 -7 5 -34 -5 -81z m-64 -198 c-21 -52 -23 -52 -23
    -5 0 35 3 42 19 42 19 0 19 -2 4 -37z m446 -44 c31 -56 -29 -163 -96 -171 -54
    -6 -83 3 -83 26 0 47 24 102 61 139 31 31 44 38 71 35 22 -2 37 -11 47 -29z
    m877 23 c22 -14 35 -53 28 -86 -12 -66 -88 -118 -156 -108 -24 4 -28 9 -28 37
    0 97 98 196 156 157z"/>
    <path d="M946 1492 c-14 -22 -86 -162 -160 -311 l-136 -271 -80 0 c-47 0 -80
    -4 -80 -10 0 -6 30 -10 69 -10 39 0 72 -4 75 -8 6 -10 -72 -140 -127 -210 -22
    -30 -67 -78 -100 -107 -61 -55 -63 -78 -3 -50 84 40 169 145 242 299 l36 77
    132 -3 131 -3 7 -87 c16 -210 86 -307 236 -325 54 -6 155 13 150 29 -2 5 -35
    10 -73 11 -145 5 -214 83 -246 276 -17 104 -18 101 56 101 37 0 65 4 65 10 0
    6 -29 10 -66 10 l-67 0 4 308 4 307 -22 3 c-16 2 -28 -7 -47 -36z m43 -72
    c-30 -287 -39 -391 -39 -446 l0 -64 -130 0 -130 0 24 53 c61 136 202 428 234
    484 20 35 39 59 43 55 3 -4 3 -41 -2 -82z"/>
    <path d="M7570 1520 c-25 -5 -83 -28 -129 -51 -70 -35 -102 -59 -180 -138
    -139 -140 -208 -268 -218 -405 -11 -143 42 -239 149 -271 125 -37 256 -2 385
    102 27 22 44 29 49 22 3 -6 3 -59 0 -117 -16 -227 -85 -332 -221 -332 -81 0
    -125 39 -125 111 0 33 47 89 74 89 9 0 16 6 16 14 0 12 -7 13 -32 5 -18 -5
    -34 -9 -36 -9 -1 0 -11 -15 -22 -32 -13 -21 -20 -51 -20 -82 0 -43 4 -53 35
    -81 42 -40 91 -50 169 -36 179 33 245 154 211 383 -20 138 -20 138 25 205 45
    69 75 165 59 194 -39 74 -129 -72 -129 -210 0 -39 -5 -49 -44 -87 -129 -123
    -317 -149 -406 -55 -63 65 -75 158 -41 293 65 254 268 452 476 466 110 7 155
    -24 155 -105 -1 -146 -193 -288 -309 -228 -49 25 -59 104 -21 150 7 9 8 15 1
    20 -16 9 -51 -55 -51 -93 0 -75 52 -118 140 -116 122 2 261 143 263 265 2 104
    -89 156 -223 129z m170 -472 c0 -33 -35 -116 -64 -153 -15 -19 -15 -19 -16 16
    0 59 45 169 70 169 6 0 10 -15 10 -32z"/>
    <path d="M5737 1500 c-52 -41 -100 -187 -114 -346 -5 -64 -8 -74 -22 -68 -9 4
    -38 9 -66 11 -92 6 -173 -65 -214 -190 -39 -116 -96 -186 -141 -172 -29 9 -35
    48 -21 146 6 48 8 95 5 106 -29 79 -112 68 -173 -23 -17 -26 -32 -52 -33 -58
    0 -6 -4 24 -8 67 -6 79 -16 99 -56 111 -41 12 -78 -34 -133 -166 -60 -141 -91
    -188 -126 -188 -36 0 -49 32 -42 109 3 36 8 73 12 82 4 11 0 18 -14 22 -17 4
    -22 -2 -31 -31 -5 -21 -10 -45 -10 -55 0 -24 -38 -102 -58 -120 -48 -44 -99
    -38 -133 15 -17 28 -20 45 -16 109 6 134 79 239 163 239 39 0 58 -22 58 -67 0
    -28 -8 -43 -38 -70 -41 -38 -41 -55 -1 -33 55 29 83 120 51 163 -8 10 -30 22
    -50 28 -46 13 -123 -19 -167 -69 -100 -114 -108 -297 -15 -353 59 -36 131 -19
    180 43 23 30 26 31 32 13 13 -37 36 -55 71 -55 57 0 96 56 172 247 34 86 60
    119 86 110 11 -5 15 -22 15 -74 -1 -62 -4 -98 -25 -250 -7 -48 -6 -53 11 -53
    15 0 24 18 43 78 34 105 86 206 122 236 62 53 84 24 63 -84 -22 -115 -19 -147
    15 -181 41 -40 74 -38 120 9 20 20 44 48 53 62 l17 25 0 -28 c1 -34 15 -63 49
    -99 54 -59 137 -45 214 35 33 34 48 45 48 33 0 -10 13 -31 29 -47 76 -75 157
    -7 235 196 l27 70 45 0 c24 0 44 -2 44 -5 0 -3 -7 -27 -15 -54 -31 -102 -11
    -202 46 -231 64 -34 131 -6 176 74 l29 49 17 -48 c37 -104 155 -120 230 -30
    29 34 37 37 37 15 0 -26 38 -55 71 -55 23 0 41 9 64 34 39 42 110 205 96 219
    -7 7 -13 4 -17 -9 -21 -58 -78 -172 -96 -191 -30 -32 -66 -30 -79 5 -10 25 -7
    110 7 158 4 15 0 23 -12 28 -22 9 -22 9 -35 -60 -21 -113 -71 -178 -134 -172
    -49 4 -73 36 -82 106 -10 85 20 174 77 234 39 40 52 48 83 48 38 0 67 -27 67
    -63 0 -27 -30 -73 -57 -88 -27 -14 -30 -29 -7 -29 9 0 32 16 50 36 27 28 34
    44 34 75 0 54 -13 75 -56 89 -29 10 -43 9 -79 -5 -84 -31 -134 -98 -189 -248
    -23 -61 -74 -129 -107 -141 -18 -6 -35 -4 -55 5 -63 30 -68 168 -10 280 29 57
    18 73 -21 29 -21 -23 -38 -31 -67 -33 l-40 -2 0 67 c-1 69 -13 95 -40 85 -23
    -9 -30 -68 -12 -111 14 -33 14 -42 0 -84 -34 -102 -93 -211 -124 -227 -48 -25
    -80 0 -93 73 -5 26 1 48 27 100 110 219 178 556 122 598 -27 19 -49 18 -79 -6z
    m74 -37 c20 -54 -27 -272 -100 -458 -50 -129 -53 -127 -47 30 12 271 59 455
    116 455 13 0 24 -10 31 -27z m-232 -394 c31 -11 31 -11 31 -82 0 -40 3 -96 6
    -125 l7 -52 -55 -55 c-46 -46 -61 -55 -90 -55 -60 0 -98 57 -98 148 0 104 43
    186 115 218 37 16 44 17 84 3z"/>
    <path d="M2287 1352 c-22 -24 -21 -45 1 -65 36 -33 102 -14 102 28 0 47 -71
    72 -103 37z m71 -34 c-3 -7 -11 -13 -18 -13 -7 0 -15 6 -17 13 -3 7 4 12 17
    12 13 0 20 -5 18 -12z"/>
    <path d="M7970 1350 c-11 -11 -20 -24 -20 -30 0 -20 41 -50 69 -50 65 0 81 59
    24 89 -31 16 -50 14 -73 -9z m70 -36 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28
    12 0 21 -6 21 -16z"/>
    <path d="M9822 1354 c-29 -20 -28 -50 2 -69 32 -21 75 -16 93 12 14 20 13 24
    -11 48 -29 29 -52 32 -84 9z m66 -36 c-3 -7 -11 -13 -18 -13 -7 0 -15 6 -17
    13 -3 7 4 12 17 12 13 0 20 -5 18 -12z"/>
    <path d="M8284 1111 c-55 -25 -110 -81 -135 -139 -62 -139 -92 -193 -120 -213
    -38 -27 -76 -19 -90 19 -14 35 -5 146 21 242 23 88 23 90 9 90 -25 0 -89 -192
    -89 -269 0 -147 125 -179 210 -53 l26 40 12 -45 c14 -52 52 -90 98 -99 50 -9
    83 3 127 47 22 22 41 38 41 37 1 -2 7 -15 14 -30 12 -30 48 -44 87 -34 36 9
    71 62 125 191 57 138 75 167 98 163 14 -3 18 -17 20 -78 3 -66 -9 -191 -25
    -272 -4 -23 -2 -28 13 -28 15 0 25 18 47 81 55 159 117 259 162 259 33 0 37
    -26 20 -127 -16 -90 -16 -101 -2 -135 18 -42 53 -64 89 -55 54 14 118 110 161
    242 36 107 88 151 102 86 6 -29 -9 -206 -25 -293 -6 -28 -10 -52 -10 -54 0 -2
    8 -4 19 -4 14 0 26 20 51 93 66 191 138 283 180 232 11 -13 11 -29 1 -85 -25
    -137 -11 -202 46 -216 42 -11 93 22 130 86 29 48 33 51 33 27 0 -36 25 -83 49
    -97 44 -23 82 -12 131 38 52 54 109 177 91 195 -7 7 -12 4 -17 -9 -38 -109
    -110 -204 -154 -204 -54 0 -65 104 -26 263 14 57 19 100 14 103 -5 3 -10 4
    -12 3 -7 -11 -57 -135 -81 -202 -44 -124 -101 -191 -144 -168 -29 16 -36 52
    -22 118 17 86 14 136 -9 161 -44 47 -114 15 -166 -74 -14 -24 -28 -44 -29 -44
    -2 0 -2 21 1 48 6 60 -6 101 -37 124 -52 39 -101 -11 -155 -162 -31 -88 -69
    -149 -106 -169 -24 -14 -28 -14 -48 4 -24 21 -24 19 -4 185 5 47 3 59 -16 83
    -42 53 -111 24 -172 -72 l-28 -45 0 72 c0 65 -3 76 -25 98 -19 19 -31 23 -48
    18 -45 -13 -47 -16 -142 -229 -20 -44 -45 -90 -57 -102 -30 -32 -66 -30 -79 5
    -10 25 -7 110 7 158 4 15 0 23 -12 28 -22 9 -22 9 -35 -60 -21 -113 -71 -178
    -134 -172 -49 4 -73 36 -82 106 -10 85 20 174 77 234 39 40 52 48 83 48 38 0
    67 -27 67 -63 0 -27 -30 -73 -57 -88 -27 -14 -30 -29 -7 -29 9 0 32 16 50 36
    27 28 34 44 34 75 0 53 -13 74 -51 88 -42 14 -47 14 -95 -8z"/>
    </g>
    </svg>
  );
}
